
import { notificationsChannels } from '@/shared/config/notifications';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { notificationSettingsTranslations } from '@/shared/config/notifications';
@Component
export default class NotificationsSettingItem extends Vue {
    @Prop({ type: Object }) public item!: any;
    @Prop(Number) public index!: number;
    @Prop({type: Boolean, default: false}) public inSubgroup!: boolean;
    public channels = notificationsChannels;

    get name() {
        const parsedName = this.removeModuleName(this.item.name);
        const translation = notificationSettingsTranslations.find(
            (translationObject) => translationObject.id === parsedName,
        );
        return `${this.inSubgroup ? '- ' : ''}${translation ? translation.name : this.item.name}`;
    }

    get isAllSelected() {
        if (!this.item) {
            return false;
        }
        return !Object.values(this.item.channels).some((val) => val === false);
    }

    public switchAllChannels() {
        const value = !this.isAllSelected;

        Object.keys(this.item.channels).forEach((key: string) => {
            this.$set(this.item.channels, key, value);
        });
    }

    private removeModuleName(name: string) {
        return name.split('_').slice(1).join('_');
    }
}
