
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Prop, Ref } from 'vue-property-decorator';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import { parseDate, parseMoment } from '@/shared/helpers';
import InfiniteScroll from '@/shared/components/elements/infinite-scroll.vue';
import moment from 'moment';
import ObservableWatch from '@/shared/components/elements/observable-watch.vue';
import { notificationsModule } from '../../shared';
import { NotificationGroup } from '../../shared/models/notification-group';
import { unreadAll, readAll } from '../../shared/services/read';
import { Notification } from '@/modules/notifications/shared/models/notification';
import { notificationsComponents } from '../../shared/config/notifications';

@Component({
    components: {
        TooltipBtn,
        InfiniteScroll,
        ObservableWatch,
        ...notificationsComponents,
    },
})
export default class NotificationListElement extends Vue {
    @Ref() public readonly list!: Vue;
    @Inject('permissionCheck') public readonly isPermitted!: (arg1: string) => boolean;
    @Prop(Array) public items!: Notification[];
    @Prop(Object) public element!: any;
    @Prop(String) public elementType!: any;
    @Prop(Boolean) public forRead!: boolean;
    @Prop(Number) public total!: number;
    @Prop(Object) public group!: NotificationGroup;
    @Prop(String) public moduleName!: string;

    public parseDate = parseDate;
    public item: any = {};
    public showNewDateDialog = false;
    public fetchAction = notificationsModule.actionsTypes.FETCH_EXTRA_NOTIFICATIONS;
    public store = this.$store.state.notificationsState;
    public loadingToggle = false;

    get elementDeleted() {
        return !this.element;
    }

    get elementName() {
        if (this.elementDeleted) {
            return this.group.element_name;
        } else if (this.element && this.element.name) {
            return this.element.name;
        } else if (this.element && this.element.number) {
            return this.element.number;
        } else {
            return '-';
        }
    }

    get itemsWithData() {
        return this.items.map((e) => {
            if (e.data.length === 0) {
                e.data = e.element;
            }
            return e;
        });
    }

    get elementDetailsRoute() {
        if (!this.elementType || !this.item) {
            return '';
        }
        if (this.elementType === 'investment_subject') {
            return `/investments/subjects/${this.item.id}`;
        }
        return `/${this.elementType}s/${this.item.id}`;
    }

    get notificationsLoading() {
        return this.store.groupsLoading.some(
            (group: any) => group.id === this.element.id && group.type === this.elementType,
        );
    }

    get fetchPayload() {
        return {
            params: {
                filters: {
                    group: {
                        type: this.elementType,
                        id: this.group.id,
                    },
                },
            },
            read: this.forRead,
        };
    }

    get ordinalDate() {
        return this.items[0].created_at;
    }

    get toggleFilters() {
        return {
            group: {
                id: this.group.id,
                type: this.group.type,
            },
            created_at: [
                moment(0).format('YYYY-MM-DD HH:mm'),
                parseMoment(this.ordinalDate).format('YYYY-MM-DD HH:mm'),
            ],
        };
    }

    get newNotificationsCount() {
        return this.store.newNotificationsCount;
    }

    set newNotificationsCount(val: number) {
        this.store.newNotificationsCount = val;
    }

    public created() {
        this.item = this.element;
    }

    public getComponent(type: keyof typeof notificationsComponents) {
        return notificationsComponents[type];
    }

    public toggleReadAll() {
        this.loadingToggle = true;
        (this.forRead ? unreadAll : readAll)({ filters: this.toggleFilters, page: 0 })
            .then((res) => {
                if (res.status === 200) {
                    const currentArrayName = this.forRead ? 'readNotifications' : 'unreadNotifications';
                    this.$set(
                        this.store,
                        currentArrayName,
                        this.store[currentArrayName].filter((notification: Notification) => {
                            if (notification.type !== this.elementType || notification.id !== this.group.id) {
                                return notification;
                            }
                        }),
                    );
                    this.$emit('readToggled');

                    if (this.forRead) {
                        this.newNotificationsCount += res.data.count;
                    } else {
                        this.newNotificationsCount -= res.data.count;
                    }

                    this.loadingToggle = false;
                } else {
                    throw new Error();
                }
            })
            .catch((err: any) => {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'error',
                    text: err.errors ? err.errors.name[0] : 'Coś poszło nie tak',
                });
                this.loadingToggle = false;
            });
    }

    public onUnfollow() {
        if (!this.elementDeleted) {
            return;
        }

        this.$emit('forceRefresh');
    }

}
