
import Vue from 'vue';
import Component from 'vue-class-component';
import NotificationListElement from '../list-element-base.vue';
import { Inject, Prop } from 'vue-property-decorator';
import { NotificationGroup } from '@/modules/notifications/shared/models/notification-group';
import ElementAction from '../element-action.vue';
import TicketUserPicker from '@/modules/tickets/components/pickers/user-picker.vue';
import TicketStatusPicker from '@/modules/tickets/components/pickers/status-picker.vue';
import TicketPriorityPicker from '@/modules/tickets/components/pickers/priority-picker.vue';
import { parseDate } from '@/shared/helpers';
import ChangeDueDateDialog from '../../dialogs/change-due-date-dialog.vue';
import { Ticket } from '@/modules/tickets/shared';
import _ from 'lodash';
import { httpClient } from '@/shared/services/http-client/http-client';
import CommentCreatedNotification from '../../notifications/comment-created.vue';
import ReminderNotification from '../../notifications/reminder.vue';

@Component({
    components: {
        NotificationListElement,
        ElementAction,
        TicketUserPicker,
        TicketStatusPicker,
        TicketPriorityPicker,
        ChangeDueDateDialog,
        CommentCreatedNotification,
        ReminderNotification,
    },
})
export default class TicketElement extends Vue {
    @Inject('permissionCheck') public readonly isPermitted!: (...args: string[]) => boolean;

    @Prop(Array) public items!: Notification[];
    @Prop(Object) public element!: any;
    @Prop(String) public elementType!: any;
    @Prop(Boolean) public forRead!: boolean;
    @Prop(Number) public total!: number;
    @Prop(Object) public group!: NotificationGroup;
    @Prop(String) public moduleName!: string;

    public item!: Ticket;
    public showNewDateDialog = false;
    public parseDate = parseDate;

    get currentUser() {
        return this.$store.state.authState.user;
    }

    get isCommentPermitted() {
        return this.isPermitted('tickets.comments.create');
    }
    get isEditPermitted() {
        return this.isPermitted('tickets.edit');
    }

    public created() {
        this.item = _.cloneDeep(this.element);
    }

    public addComment(content: string) {
        httpClient
            .post(`api/v1/tickets/${this.element.id}/comments`, { content, user: this.currentUser })
            .then((res) => {
                if (res.status === 201) {
                    this.$store.commit('SHOW_SNACKBAR', { type: 'success', text: 'Dodano komentarz' });
                } else {
                    throw new Error(res.statusText);
                }
            })
            .catch((err) => {
                this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
            })
            .finally(() => {
                this.onElementChanged();
            });
    }

    public onElementChanged() {
        httpClient.get(`api/v1/tickets/${this.element.id}`).then(({ data }) => {
            this.$set(this.item, 'due_date', data.data.due_date);
            this.$nextTick(() => {
                this.$forceUpdate();
            });
        });
    }
}
