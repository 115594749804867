
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { Prop } from 'vue-property-decorator';

    @Component({})
    export default class ElementAction extends Vue {
        @Prop(String) public name!: string;
        @Prop(Boolean) public popup!: boolean;
    }
