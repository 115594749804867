import { objectToQueryString } from '@/shared/helpers';
import { httpClient } from '@/shared/services';
import { FetchParams } from '@/shared/types';

export const readAll = (params: FetchParams) => {
    const url  = `/api/v1/notifications/read?${objectToQueryString(params)}`;
    return httpClient.post(url);
};
export const unreadAll = (params: FetchParams) => {
    const url  = `/api/v1/notifications/unread?${objectToQueryString(params)}`;
    return httpClient.post(url);
};
