
import Vue from 'vue';
import Component from 'vue-class-component';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import { Inject, Prop, VModel } from 'vue-property-decorator';
import { privateStatuses, publicStatuses } from '@/shared/config/investments';
import { investmentSubjectsModule } from '@/modules/investments/shared/state/submodules';

@Component({
    components: {
        TextContrast,
    },
})
export default class StatusPicker extends Vue {
    @Inject() public readonly permissionCheck!: (arg1: string) => boolean;
    @VModel({type: String}) public statusValue!: string;
    @Prop(Boolean) public loading!: boolean;
    @Prop(Number) public investmentSubjectId!: number;
    @Prop({ type: Boolean, default: false }) public private!: string;
    @Prop({ type: Boolean, default: true }) public updateItem!: boolean;

    public actionTypes = investmentSubjectsModule.actionsTypes;

    get statuses() {
        if (this.private) {
            return privateStatuses;
        } else {
            return publicStatuses;
        }
    }

    get selectedStatus() {
        if (!this.statusValue) {
            return {
                value: 'unknown',
                name: 'Nieznany',
            };
        } else {
            return this.statuses.find((status) => status.value === this.statusValue);
        }
    }

    public selectStatus(statusValue: string) {
        if (statusValue === this.statusValue) {
            return;
        }
        this.$set(this, 'statusValue', statusValue);
        if (this.updateItem) {
            this.$store.dispatch(this.actionTypes.UPDATE_ITEM, {
                id: this.investmentSubjectId,
                [this.private ? 'private_status' : 'public_status']: statusValue,
            });
        }
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`investments.subjects.${action}`);
    }
}
