
import { notificationsChannels, notificationTypesTranslations } from '@/shared/config/notifications';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Setting } from '../../shared/models/setting';
import SettingItem from './setting-item.vue';

@Component({
    components: {
        SettingItem,
    },
})
export default class NotificationsSettingsSubgroup extends Vue {
    @Prop({ type: Array }) public subGroup!: any;
    @Prop(String) public groupName!: string;
    public channels = notificationsChannels;

    get name() {
        const translation = notificationTypesTranslations.find(
            (translationObject) => translationObject.id === this.groupName,
        );
        return translation ? translation.name : this.groupName;
    }

    get emptyChannels() {
        const result: string[] = [];
        notificationsChannels.forEach(({ id }) => {
            if (this.isChannelEmpty(id)) {
                result.push(id);
            }
        });
        return result;
    }

    get fullCheckedChannels() {
        const result: string[] = [];
        notificationsChannels.forEach(({ id }) => {
            if (this.isChannelOnlyTrue(id)) {
                result.push(id);
            }
        });

        return result;
    }

    public switchAllByChannel(channelId: string) {
        if (this.isChannelEmpty(channelId)) {
            return;
        }

        const value = !this.isChannelOnlyTrue(channelId);

        this.subGroup.forEach((setting: Setting) => {
            if (setting.channels.hasOwnProperty(channelId)) {
                this.$set(setting.channels, channelId, value);
            }
        });
    }

    private isChannelEmpty(channelId: string) {
        const allChannels = this.subGroup.map((setting: Setting) => setting.channels);
        const channelExisteneceArray = allChannels.map((element: any) => element.hasOwnProperty(channelId));
        return channelExisteneceArray.some((val: boolean) => val === false);
    }

    private isChannelOnlyTrue(channelId: string) {
        const allChannels = this.subGroup.map((setting: Setting) => setting.channels);
        const allChoices = allChannels.map((element: any) =>
            element.hasOwnProperty(channelId) ? element[channelId] : true,
        );
        return !allChoices.some((choice: boolean) => choice === false);
    }
}
