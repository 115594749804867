
import Vue from 'vue';
import Component from 'vue-class-component';
import { costStatuses } from '@/shared/config/costs';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import { Inject, Prop, VModel } from 'vue-property-decorator';
import { costsModule } from '../../shared/state/module';

@Component({
    components: {
        TextContrast,
    },
})
export default class StatusPicker extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;
    @Prop(Boolean) public loading!: boolean;
    @VModel({type: String}) public statusId!: string;
    @Prop([String, Number]) public costId!: string;
    @Prop({type: Boolean, default: true}) public updateItem!: boolean;
    @Prop({type: Boolean, default: false}) public externalUpdateAction!: boolean;

    public actionsTypes = costsModule.actionsTypes;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public costStatuses = costStatuses;

    get costStatusesFiltered() {
        return costStatuses.filter((e) =>
            this.selectedStatus && e.setAfter ? e.setAfter.includes(this.selectedStatus.id) : null,
        );
    }

    get selectedStatus() {
        if (!this.statusId) {
            return {
                id: 'unknown',
                name: 'Nieznany',
                color: '#f7f7f7',
                namePlural: 'Nieznane',
                setAfter: [],
            };
        }
        const status = costStatuses.find((e) => e.id === this.statusId);
        if (status) {
            return status;
        }

        return {
            id: 'unknown',
            name: 'Nieznany',
            color: '#00bdb0',
            namePlural: 'Nieznane',
            setAfter: [],
        };
    }

    public selectStatus(statusId: string) {
        if (statusId === this.statusId) {
            return;
        }
        this.statusId = statusId;

        if (this.externalUpdateAction) { return; }

        this.$store.dispatch(this.updateAction, { id: this.costId, status: statusId });
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`costs.${action}`);
    }
}
