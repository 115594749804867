import { render, staticRenderFns } from "./settings-group.vue?vue&type=template&id=2d3e6574&"
import script from "./settings-group.vue?vue&type=script&lang=ts&"
export * from "./settings-group.vue?vue&type=script&lang=ts&"
import style0 from "./settings-group.vue?vue&type=style&index=0&id=2d3e6574&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBadge,VBtn,VCard,VDivider,VSimpleTable})
