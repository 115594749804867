
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import NotificationListElement from '../list-element-base.vue';
    import { Prop } from 'vue-property-decorator';
    import { NotificationGroup } from '@/modules/notifications/shared/models/notification-group';
    import ElementAction from '../element-action.vue';
    import ChangeDueDateDialog from '../../dialogs/change-due-date-dialog.vue';
    import _ from 'lodash';
    import InvestmentSubjectStatusPicker from '@/modules/investments/components/subjects/pickers/status-picker.vue';

    @Component({
        components: {
            NotificationListElement,
            ElementAction,
            ChangeDueDateDialog,
            InvestmentSubjectStatusPicker,
        },
    })
    export default class InvestmentSubjectElement extends Vue {
        @Prop(Array) public items!: Notification[];
        @Prop(Object) public element!: any;
        @Prop(String) public elementType!: any;
        @Prop(Boolean) public forRead!: boolean;
        @Prop(Number) public total!: number;
        @Prop(Object) public group!: NotificationGroup;
        @Prop(String) public moduleName!: string;
    }
