
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, VModel } from 'vue-property-decorator';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import { httpClient } from '@/shared/services';

@Component({
    components: {
        DatePicker,
    },
})
export default class ChangeDueDateDialog extends Vue {
    @VModel({ type: Boolean }) public showDialog!: boolean;
    @Prop(String) public initalDate!: string;
    @Prop(String) public url!: string;

    public newDate = '';

    public onDatePicked(date: string) {
        this.newDate = date;
    }

    public save() {
        httpClient.put(this.url, {due_date: this.newDate}).then((res) => {
            if (res.status === 202) {
                this.$store.commit('SHOW_SNACKBAR', { type: 'success', text: 'Termin zmieniony' });
            } else { throw new Error(res.statusText); }
        }).catch((err) => {
                this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
        }).finally(() => {
            this.showDialog = false;
            this.$emit('elementChanged');
        });
    }
}
