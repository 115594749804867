
import { polishModuleNames } from '@/shared/config/notifications';
import { httpClient, logger } from '@/shared/services';
import { AxiosResponse } from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';
import { notificationsModule } from '../../shared';
import NotificationsSettingsGroup from './settings-group.vue';
import ListToolbar from '@/shared/components/layout/list/list-toolbar.vue';

@Component({
    components: {
        ListToolbar,
        NotificationsSettingsGroup,
    },
    beforeRouteEnter(to, from, next) {
        window.scrollTo(0, 0);
        next();
    },
})
export default class NotificationsSettings extends Vue {
    public groups = {};
    public store = this.$store.state.notificationsState;
    public actionTypes = notificationsModule.actionsTypes;
    public activeRequestCount = 0;

    public created() {
        this.fetchSettings();
    }

    get loading() {
        return this.store.settingsLoading;
    }

    set loading(val: boolean) {
        this.$set(this.store, 'settingsLoading', val);
    }

    public fetchSettings() {
        httpClient.get('/api/v1/notifications/settings/groups').then(({ data }: AxiosResponse) => (this.groups = data));
    }

    public getPolishName(name: string) {
        const polishNames = polishModuleNames.find((translation: any) => translation.id === name);
        return polishNames ? polishNames.namePlural : name;
    }

    public saveSettings({ data, action }: any) {
        const vuexAction = action === 'enable' ? this.actionTypes.ENABLE_SETTINGS : this.actionTypes.DISABLE_SETTINGS;
        this.activeRequestCount++;
        this.$store
            .dispatch(vuexAction, data)
            .catch((err) => logger.error(err))
            .then(() => {
                this.activeRequestCount--;
                if (this.activeRequestCount === 0) {
                    this.$store.commit('SHOW_SNACKBAR', { type: 'success', text: 'Zaktualizowano użytkownika' });
                }
            });
    }
}
