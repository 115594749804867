
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import NotificationListElement from '../list-element-base.vue';
    import { Prop } from 'vue-property-decorator';
    import { NotificationGroup } from '@/modules/notifications/shared/models/notification-group';
    import ElementAction from '../element-action.vue';
    import { parseDate } from '@/shared/helpers';
    import CostStatusPicker from '@/modules/costs/components/details/status-picker.vue';
    import { Cost } from '@/modules/costs/shared/models/cost';
    import { httpClient } from '@/shared/services';
    import _ from 'lodash';
    import ChangeDueDateDialog from '../../dialogs/change-due-date-dialog.vue';

    @Component({
        components: {
            NotificationListElement,
            ElementAction,
            CostStatusPicker,
            ChangeDueDateDialog,
        },
    })
    export default class CostElement extends Vue {
        @Prop(Array) public items!: Notification[];
        @Prop(Object) public element!: any;
        @Prop(String) public elementType!: any;
        @Prop(Boolean) public forRead!: boolean;
        @Prop(Number) public total!: number;
        @Prop(Object) public group!: NotificationGroup;
        @Prop(String) public moduleName!: string;

        public showNewDateDialog = false;
        public item!: Cost;
        public parseDate = parseDate;

        public created() {
            this.item = _.cloneDeep(this.element);
        }
        public onElementChanged() {
            httpClient.get(`api/v1/costs/${this.element.id}`).then(({ data }) => {
                this.$set(this.item, 'due_date', data.data.due_date);
                this.$nextTick(() => {
                    this.$forceUpdate();
                });
            });
        }
    }
