
import Vue from 'vue';
import Component from 'vue-class-component';
import { notificationsModule } from '../shared';
import { Watch } from 'vue-property-decorator';
import InfiniteScroll from '@/shared/components/elements/infinite-scroll.vue';
import { readAll } from '../shared/services/read';
import { isMApp } from '@/shared/helpers';
import ListToolbar from '@/shared/components/layout/list/list-toolbar.vue';
import TicketElement from './Elements/Tickets/TicketElement.vue';
import SaleElement from './Elements/Sales/SaleElement.vue';
import CostElement from './Elements/Costs/CostElement.vue';
import AcceptanceElement from './Elements/Acceptances/AcceptanceElement.vue';
import InvestmentSubjectElement from './Elements/InvestmentSubjects/InvestmentSubjectElement.vue';

@Component({
        components: {
            ListToolbar,
            InfiniteScroll,
            TicketElement,
            SaleElement,
            CostElement,
            AcceptanceElement,
            InvestmentSubjectElement,
        },
    })
    export default class NotificationsList extends Vue {
        public currentTab = 0;
        public activeTabClass = { 'primary': true, 'primary--text': true };
        public store = this.$store.state.notificationsState;
        public fetchAction = notificationsModule.actionsTypes.FETCH_NOTIFICATION_GROUPS;
        public extraFetchAction = notificationsModule.actionsTypes.FETCH_EXTRA_NOTIFICATION_GROUPS;
        public isMApp = isMApp;

        get meta() {
            return this.store.meta;
        }

        get notifications() {
            if (this.store.data) {
                return this.store.data;
            } else {
                return [];
            }
        }

        get loading() {
            return this.store.loading;
        }

        set loading(val: boolean) {
            this.store.loading = val;
        }

        get groups() {
            if (this.currentTab === 0) {
                return this.store.unreadNotifications;
            } else {
                return this.store.readNotifications;
            }
        }

        get user() {
            return this.$store.state.authState.user;
        }

        get showRefreshBanner() {
            if (this.store.notificationsQueue.length > 0 && this.currentTab === 0) {
                return true;
            }
            return false;
        }

        public getNotificationListElement(type: string) {
            switch (type) {
                case 'ticket':
                    return 'TicketElement';
                case 'sale':
                    return 'SaleElement';
                case 'cost':
                    return 'CostElement';
                case 'acceptance':
                    return 'AcceptanceElement';
                case 'investment_subject':
                    return 'InvestmentSubjectElement';
            }
        }

        @Watch('currentTab', { immediate: true })
        public fetchNotifications() {
            this.store.notificationsQueue = [];
            this.$store.dispatch(this.fetchAction, { filters: { read: !!this.currentTab }, infinity_scroll: true });
        }
    }
