
import { notificationsChannels } from '@/shared/config/notifications';
import _ from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Setting } from '../../shared/models/setting';
import SettingItem from './setting-item.vue';
import SettingsSubgroup from './settings-subgroup.vue';

@Component({
    components: {
        SettingItem,
        SettingsSubgroup,
    },
})
export default class NotificationsSettingsGroup extends Vue {
    @Prop(Object) public group!: {};
    @Prop(String) public name!: string;

    public allSettingsCopy!: Setting[];
    public channels = notificationsChannels;
    public unwatch!: any;

    get emptyChannels() {
        const result: string[] = [];
        notificationsChannels.forEach(({ id }) => {
            if (this.isChannelEmpty(id)) {
                result.push(id);
            }
        });
        return result;
    }

    get fullCheckedChannels() {
        const result: string[] = [];
        notificationsChannels.forEach(({ id }) => {
            if (this.isChannelOnlyTrue(id)) {
                result.push(id);
            }
        });

        return result;
    }

    get allSettings(): Setting[] {
        if (!this.group) {
            return [];
        }
        return Object.values(this.group).reduce((arr, subGroup) => {
            (arr as Setting[]).push(...(subGroup as Setting[]));
            return arr;
        }, [] as Setting[]) as Setting[];
    }

    public created() {
        this.allSettingsCopy = _.cloneDeep(this.allSettings);
    }

    public switchAllByChannel(channelId: string) {
        if (this.isChannelEmpty(channelId)) {
            return;
        }

        const value = !this.isChannelOnlyTrue(channelId);

        this.allSettings.forEach((setting: Setting) => {
            if (setting.channels.hasOwnProperty(channelId)) {
                this.$set(setting.channels, channelId, value);
            }
        });
    }

    private isChannelEmpty(channelId: string) {
        const allChannels = this.allSettings.map((setting: Setting) => setting.channels);
        const channelExisteneceArray = allChannels.map((element: any) => element.hasOwnProperty(channelId));
        return channelExisteneceArray.some((val: boolean) => val === false);
    }

    private isChannelOnlyTrue(channelId: string) {
        const allChannels = this.allSettings.map((setting: Setting) => setting.channels);
        const allChoices = allChannels.map((element: any) =>
            element.hasOwnProperty(channelId) ? element[channelId] : true,
        );
        return !allChoices.some((choice: boolean) => choice === false);
    }

    private setupForUpdate(dataToSetup: Setting[]) {
        const diff: any = {};
        const data: any = {};

        notificationsChannels.forEach(({ id }) => {
            // Picks updated settings
            diff[id] = this.getDifferenceBy(
                this.allSettingsCopy,
                dataToSetup,
                (val: Setting) => val.channels[id],
            ) as Setting[];

            // Maps updated settings into {name:string, value:boolean} format
            data[id] = (diff[id] ? diff[id] : []).map((setting: Setting) => {
                return { name: setting.name, value: setting.channels[id] };
            });
        });

        const action = this.determineSaveAction(data);
        const dataToSave: any = this.setupDataForSave(data);

        return { dataToSave, action };
    }

    private setupDataForSave(data: any) {
        const dataToSave: any = {};
        for (const [key, value] of Object.entries(data)) {
            dataToSave[key] = (value as any[]).map((el) => el.name);
        }

        return dataToSave;
    }

    private determineSaveAction(data: any) {
        // Merges all updated settings into one array and maps into their values
        const values = [].concat(...(Object.values(data) as any)).map((el: { name: string; value: boolean }) => {
            return el.value;
        });

        if (values.every((val: boolean) => val === true)) {
            return 'enable';
        } else {
            return 'disable';
        }
    }

    // lODASH differenceBy DIDNT WORK AS EXPECTED
    private getDifferenceBy(arr1: any[], arr2: any[], cb: (val: any) => any) {
        const diff: any[] = [];
        for (const index in arr1) {
            if (_.isEqual(cb(arr1[index]), cb(arr2[index]))) {
                continue;
            }
            diff.push(arr2[index]);
        }
        return diff;
    }

    @Watch('allSettings', { deep: true })
    private onSettingsChange(newVal: any) {
        const { dataToSave, action } = this.setupForUpdate(newVal);

        this.$set(this, 'allSettingsCopy', _.cloneDeep(newVal));
        this.$emit('settingsChanged', { data: dataToSave, action });
    }
}
