
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import NotificationListElement from '../list-element-base.vue';
    import { Inject, Prop } from 'vue-property-decorator';
    import { NotificationGroup } from '@/modules/notifications/shared/models/notification-group';
    import ElementAction from '../element-action.vue';
    import SaleUserPicker from '@/modules/sales/components/pickers/user-picker.vue';
    import SaleStatusPicker from '@/modules/sales/components/pickers/status-picker.vue';
    import { httpClient } from '@/shared/services';
    import _ from 'lodash';
    import CommentCreatedNotification from '../../notifications/comment-created.vue';

    @Component({
        components: {
            NotificationListElement,
            ElementAction,
            SaleUserPicker,
            SaleStatusPicker,
            CommentCreatedNotification,
        },
    })
    export default class SaleElement extends Vue {
        @Inject('permissionCheck') public readonly isPermitted!: (...args: string[]) => boolean;

        @Prop(Array) public items!: Notification[];
        @Prop(Object) public element!: any;
        @Prop(String) public elementType!: any;
        @Prop(Boolean) public forRead!: boolean;
        @Prop(Number) public total!: number;
        @Prop(Object) public group!: NotificationGroup;
        @Prop(String) public moduleName!: string;

        get currentUser() {
            return this.$store.state.authState.user;
        }

        get isCommentPermitted() {
            return this.isPermitted('tickets.comments.create');
        }
        get isEditPermitted() {
            return this.isPermitted('tickets.edit');
        }

        public addComment(content: string) {
            httpClient
                .post(`api/v1/sales/${this.element.id}/notes`, { content, user: this.currentUser })
                .then((res) => {
                    if (res.status === 201) {
                        this.$store.commit('SHOW_SNACKBAR', { type: 'success', text: 'Dodano komentarz' });
                    } else {
                        throw new Error(res.statusText);
                    }
                })
                .catch((err) => {
                    this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
                });
        }
    }
